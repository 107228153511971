<template>
  <div>
    <h1 class="text-zinc-700 text-lg font-semibold mb-2">
      Preview:
      <span class="font-medium text-green-700">{{ nome }}</span>
    </h1>
    <div class="rounded-md bg-chat p-4" :class="aumentarTamanhoDoFundo() ? 'h-full' : 'h-48'">
      <div v-if="header || body || footer || image || document" class="bg-white py-2 px-4 rounded-md w-11/12 md:w-3/4 h-auto" :class="{ 'left-2': edit, 'md:left-20': !edit }">
        <h2 class="text-zinc-700 font-semibold mb-2">{{ header }}</h2>
        <img v-if="image && !document" alt="Imagem do template" :src="image" />
        <div v-if="document" class="bg-gray-100 rounded-md p-2 mb-4">
          <div class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="#222020" viewBox="0 0 256 256">
              <path
                d="M52,144H36a8,8,0,0,0-8,8v56a8,8,0,0,0,8,8H52a36,36,0,0,0,0-72Zm0,56H44V160h8a20,20,0,0,1,0,40Zm169.53-4.91a8,8,0,0,1,.25,11.31A30.06,30.06,0,0,1,200,216c-17.65,0-32-16.15-32-36s14.35-36,32-36a30.06,30.06,0,0,1,21.78,9.6,8,8,0,0,1-11.56,11.06A14.24,14.24,0,0,0,200,160c-8.82,0-16,9-16,20s7.18,20,16,20a14.24,14.24,0,0,0,10.22-4.66A8,8,0,0,1,221.53,195.09ZM128,144c-17.65,0-32,16.15-32,36s14.35,36,32,36,32-16.15,32-36S145.65,144,128,144Zm0,56c-8.82,0-16-9-16-20s7.18-20,16-20,16,9,16,20S136.82,200,128,200ZM48,120a8,8,0,0,0,8-8V40h88V88a8,8,0,0,0,8,8h48v16a8,8,0,0,0,16,0V88a8,8,0,0,0-2.34-5.66l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40v72A8,8,0,0,0,48,120ZM160,51.31,188.69,80H160Z"
              ></path>
            </svg>
            <span class="ml-4 text-lg text-black" v-tooltip="'O nome do arquivo pode ser reduzido para fins estéticos'">{{ formataTexto(document) }}</span>
          </div>
          <p class="text-sm text-gray-400 text-center">Arquivos aceitos: .pdf e .DOCX</p>
        </div>
        <p v-html="getHtmlFrom(body)" class="text-zinc-500 mb-2 break-words"></p>
        <p class="text-gray-400 text-sm mb-3">{{ footer }}</p>
      </div>
      <div v-else class="flex space-x-2 justify-center items-center bg-white h-8 w-1/3 xl:w-1/5 rounded-md">
        <span class="sr-only">Loading...</span>
        <div class="h-1.5 w-1.5 bg-black rounded-full animate-bounce [animation-delay:-0.3s]"></div>
        <div class="h-1.5 w-1.5 bg-black rounded-full animate-bounce [animation-delay:-0.15s]"></div>
        <div class="h-1.5 w-1.5 bg-black rounded-full animate-bounce"></div>
      </div>
      <button
        v-for="(botao, index) in buttons"
        :key="index"
        type="button"
        class="block justify-center mt-2 py-2 w-56 border border-transparent shadow-lg text-sm font-medium rounded-md text-blue-500 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
      >
        {{ botao.text }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: { edit: Boolean, nome: String, body: String, header: String, footer: String, buttons: Array, examples: Array, image: String, document: String },
  methods: {
    getHtmlFrom(str) {
      if (str) {
        const regex = /{{(\d+)}}/g;

        return str
          .replace(/(?:\*)([^*]*)(?:\*)/gm, "<strong>$1</strong>")
          .replace("{#", "<b>Contato: </b>")
          .replace("#}", "")
          .replace(/(?:_)([^_]*)(?:_)/gm, "<i>$1</i>")
          .replace(/(?:~)([^~]*)(?:~)/gm, "<strike>$1</strike>")
          .replace(/\n/im, "<br/>")
          .replace(/(?:```)([^```]*)(?:```)/gm, "<tt>$1</tt>")
          .replace(regex, this.replacer);
      }

      return str;
    },

    removerBotao(botao) {
      this.$emit("removerBotao", botao);
    },

    aumentarTamanhoDoFundo() {
      return this.body.length > 50 || this.buttons.length >= 2 || (this.body.match(/\n/g) || []).length >= 5 || this.image;
    },

    formataTexto(texto) {
      const limiteCaracteres = 20;

      if (texto.length > limiteCaracteres) {
        const extensao = texto.split(".").pop();
        const nomeArquivo = texto.substring(0, 13);
        return `${nomeArquivo}[...].${extensao}`;
      }

      return texto;
    },

    replacer(match, number) {
      if (this.edit) {
        const index = parseInt(number) - 1;
        const replacement = this.examples[index] || match;

        return `<strong">${replacement}</strong>`;
      }

      return match;
    }
  }
};
</script>
